import React from "react";
import logo from "../../assets/images/android-chrome-512x512.png";
import x from "../../assets/icons/X.png";
import styles from "../../styles/Footer.module.scss";
const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.logoLine}>
        <a href='/'>
          <img src={logo} alt='logo' />
        </a>
        <a
          href='https://twitter.com/_HydraLabs'
          target='_blank'
          rel='noreferrer noopener'
        >
          <img src={x} alt='x' />
        </a>
      </div>
      <div className={styles.separator}></div>
      <div className={styles.copyright}>
        <span>2023 HydraLabs. All right reserved.</span>
        <span>Privacy Policy</span>
        <span>Terms of Service</span>
        <span>Cookies Settings</span>
      </div>
    </footer>
  );
};

export default Footer;
