import React from "react";
import styles from "../styles/Home.module.scss";
import Header from "./commons/Header";
import hydra from "../assets/images/AK_-_Logo_No_Background.png";
import line from "../assets/icons/Vector 1.svg";
import Footer from "./commons/Footer";
const Home = () => {
  return (
    <div className={styles.main}>
      <Header />
      <div className={styles.container}>
        <div className={styles.hero}>
          <div className={styles.heroTitle}>
            <div className={styles.title}>
              HydraLabs, a pioneering force in the realm of blockchain
              technology.
            </div>
            <div className={styles.description}>
              We are dedicated to pushing the boundaries of innovation,
              harnessing the transformative potential of blockchain to
              revolutionize industries worldwide. With a focus on cutting-edge
              solutions, security, and transparency, we strive to empower
              individuals through decentralized, trustless systems. Our team's
              expertise and commitment drive us to create robust, scalable, and
              user-centric blockchain solutions that redefine the future of
              technology.
            </div>
          </div>
          <div className={styles.img}>
            <img src={hydra} alt='hydra' />
          </div>
        </div>
        <div className={styles.line}>
          <img src={line} alt='line' />
        </div>

        <div className={styles.join}>
          <div className={styles.title}>The HydraVerse</div>
          <div className={styles.images}>
            <div className={styles.content}>
              <div className={styles.texts}>
                <span className={styles.title}>ColiseumCCCX</span>
                <span className={styles.describe}>
                  Cultivating financial freedom through collective strength, we
                  are a dedicated community of 300 trend catchers, uniting to
                  harness emerging opportunities and monetize evolving trends.
                  With our eyes set on the future, our mission is to empower
                  each member towards financial strength through collaborative
                  insight and collective action.
                </span>
              </div>
            </div>
            
            {/* <div className={styles.imageTwo}></div> */}
            <div className={styles.contenttwo}>
              <div className={styles.textstwo}>
                <span className={styles.titletwo}>AirdropFarming</span>
                <span className={styles.describetwo}>
                 The most advanced airdrop farming software on the market.
                </span>
              </div>
            </div>
          
            <div className={styles.soon}>Coming Soon.</div>
          </div>
        </div>
        <div className={styles.line}>
          <img src={line} alt='line' />
        </div>
        <div className={styles.buttons}>
          <div className={styles.title}>Join Us</div>
          <div className={styles.actionButtons}>
            <button>Partner With Us</button>
            <button>Contact Us</button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
