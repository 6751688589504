import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Second from "./components/Second";
function App() {
  return (
    <Routes>
      <Route path='/' element={<Home />}></Route>
      <Route path='/page2' element={<Second />}></Route>
    </Routes>
  );
}

export default App;
