import React from "react";
import logo from "../../assets/images/Revised Logo 2 - AK 1.svg";
import x from "../../assets/icons/Frame.svg";
import Facebook from "../../assets/icons/Facebook.svg";
import Instagram from "../../assets/icons/Instagram.svg";
import LinkedIn from "../../assets/icons/LinkedIn.svg";
import styles from "../../styles/Footer.module.scss";
const FooterTwo = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.logoLine}>
        <img src={logo} alt='logo' />
        <div className={styles.menu}>
          <span>Home</span>
          <span>Join Us</span>
        </div>
        <div>
          <img src={Facebook} alt='Facebook' />
          <img src={Instagram} alt='Instagram' />
          <img src={x} alt='x' />
          <img src={LinkedIn} alt='LinkedIn' />
        </div>
      </div>
      <div className={styles.separator}></div>
      <div className={styles.copyright}>
        <span>2023 HydraLabs. All right reserved.</span>
        <span>Privacy Policy</span>
        <span>Terms of Service</span>
        <span>Cookies Settings</span>
      </div>
    </footer>
  );
};

export default FooterTwo;
