import React from "react";
import Header from "./commons/Header";
import FooterTwo from "./commons/FooterTwo";
import styles from "../styles/Second.module.scss";
import hydraPhoto from "../assets/images/31ak_Hydra_with_three_heads_technology_mythology_hyper_realisti_9fb3980a-e5a1-437a-ade3-305cdd634faa-PhotoRoom 1.svg";
import line from "../assets/icons/Vector 1 (1).svg";
import imageOne from "../assets/images/image_123650291 (1) 1.svg";
import imageTwo from "../assets/images/Rectangle 18680.svg";
const Second = () => {
  return (
    <div className={styles.main}>
      <Header />
      <div className={styles.container}>
        <div className={styles.hero}>
          <div className={styles.heroTitle}>
            <div className={styles.title}>
              HydraLabs, a pioneering force in the realm of blockchain
              technology.
            </div>
            <div className={styles.description}>
              HydraLabs, a pioneering force in the realm of blockchain
              technology. We are dedicated to pushing the boundaries of
              innovation, harnessing the transformative potential of blockchain
              to revolutionize industries worldwide. With a focus on
              cutting-edge solutions, security, and transparency, we strive to
              empower individuals through decentralized, trustless systems. Our
              team's expertise and commitment drive us to create robust,
              scalable, and user-centric blockchain solutions that redefine the
              future of technology.
            </div>
            <div>
              <button>Get Started</button>
            </div>
          </div>
          <div className={styles.img}>
            <img src={hydraPhoto} alt='hydra' />
          </div>
        </div>
        <div className={styles.line}>
          <img src={line} alt='line' />
        </div>
        <div className={styles.hydraVerse}>
          <div className={styles.images}>
            <div className={styles.content}>
              <img src={imageOne} alt='imageOne' />
              <div className={styles.texts}>
                <span className={styles.title}>ColiseumCCCX</span>
                <span className={styles.describe}>
                  Cultivating financial freedom through collective strength, we
                  are a dedicated community of 300 trend catchers, uniting to
                  harness emerging opportunities and monetize evolving trends.
                  With our eyes set on the future, our mission is to empower
                  each member towards financial strength through collaborative
                  insight and collective action.
                </span>
              </div>
            </div>
            <img src={imageTwo} alt='imageTwo' />
            <div className={styles.soon}>Coming Soon.</div>
          </div>
          <div className={styles.titleHydra}>The HydraVerse</div>
        </div>
        <div className={styles.buttons}>
          <div className={styles.title}>Join Us</div>
          <div className={styles.actionButtons}>
            <button>Partner With Us</button>
            <button>Contact Us</button>
          </div>
        </div>
      </div>
      <FooterTwo />
    </div>
  );
};

export default Second;
