import React from "react";
import logo from "../../assets/images/android-chrome-512x512.png";
import styles from "../../styles/Header.module.scss";
const Header = () => {
  return (
    <header className={styles.navbar}>
      <a href='/'>
        <img src={logo} alt='Logo' className={styles.logo} />
      </a>
      {/* <div class='hamburger-menu'>
        <input id='menu__toggle' type='checkbox' />
        <label class='menu__btn' for='menu__toggle'>
          <span></span>
        </label>

        <ul class='menu__box'>
          <li>
            <a class='menu__item' href='/'>
              Home
            </a>
          </li>

          <li>
            <a class='menu__item' href='#/'>
              About
            </a>
          </li>
          <li>
            <a class='menu__item' href='#/'>
              Team
            </a>
          </li>
          <li>
            <a class='menu__item' href='#/'>
              Contact
            </a>
          </li>
          <li>
            <a class='menu__item' href='#/'>
              Twitter
            </a>
          </li>
        </ul>
      </div> */}
    </header>
  );
};

export default Header;
